import { GlobalStyle } from '@designsforhealth/dfh-react-components'
import { PageProps } from 'gatsby'
import React, { FC } from 'react'

import { CategoryPageTemplateQuery } from '../../../graphql/gatsby'
import Head from '../../../layouts/Head'
import { CategoryPageContext as PageContext } from '../../../lib/pages'
import Layout from '../../global/Layout'
import ProductsContainer from './ProductsContainer'

export type CategoryPageProps = Pick<
  PageProps<CategoryPageTemplateQuery, PageContext>,
  'data' | 'pageContext'
>

export const CategoryPage: FC<CategoryPageProps> = ({ data }) => {
  const { magentoCategory } = data
  return (
    <>
      <Head title={magentoCategory?.name ?? 'Products'} />
      <GlobalStyle includeTypography />
      <Layout>
        <ProductsContainer magentoCategory={magentoCategory} />
      </Layout>
    </>
  )
}
